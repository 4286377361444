<template>
  <div>
    <b-card>
      <b-button
        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
        variant="outline-primary"
        class="mb-75"
        block
        :to="{
          name: modulefield,
        }"
      >
        <feather-icon icon="ArrowLeftIcon" class="mr-25" /> Back
      </b-button>
      <b-button
        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
        variant="outline-danger"
        class="mb-75"
        block
        @click="checkdeletefun(id)"
      >
        <feather-icon icon="TrashIcon" class="mr-25" /> Delete
      </b-button>
      <b-button
        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
        variant="outline-primary"
        class="mb-75"
        block
        :to="{
          name: modulefield + '-edit',
          params: { id: id },
        }"
      >
        <feather-icon icon="Edit3Icon" class="mr-25" /> Edit
      </b-button>
      <!-- <b-button
        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
        variant="outline-secondary"
        class="mb-75"
        block
        @click="printview"
      >
        <feather-icon icon="PrinterIcon" class="mr-25" /> Print
      </b-button> -->
    </b-card>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BFormDatepicker,
  BForm,
  BFormGroup,
  BFormTextarea,
  BFormInput,
  BFormCheckbox,
  BFormInvalidFeedback,
  BButton,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, amount } from "@validations";
import { ref, onUnmounted } from "@vue/composition-api";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
export default {
  props: ["modulefield", "id", "checkdeletefun", "printview", "submodulefield"],
  components: {
    BCol,
    BCard,
    BRow,
    BForm,
    BCardBody,
    BFormGroup,
    BFormCheckbox,
    BFormTextarea,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BFormDatepicker,
    vSelect,
  },
  directives: {
    Ripple,
  },
  setup(props, { emit }) {
    const { refFormObserver, getValidationState } = formValidation();
    return {
      refFormObserver,
      getValidationState,
    };
  },
  data() {
    return {
      required,
      amount,
    };
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
