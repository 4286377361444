import axios from '@axios'

export default {
  namespaced: true,
  water: {},
  getters: {},
  mutations: {},
  actions: {
    fetchDatas(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .get('metadata/water', {
            params: userData
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchData(ctx, {
      id
    }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`metadata/water/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addData(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .post('metadata/water', userData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    editData(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .put(`metadata/water/${userData.id}`,
            userData
          )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteData(ctx, {
      id
    }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`metadata/water/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    downloadDocument(ctx, {
      id
    }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`v1/app/documents/download/${id}`, {
            responseType: 'blob'
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteDocument(ctx, {
      id
    }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`v1/app/documents/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchAdminDatas(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/adminmeta/state')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchestate(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/meta/state')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}